import { Divider, Layout } from 'antd';
import type { Dispatch, SetStateAction } from 'react';
import { memo } from 'react';
import styled from 'styled-components';

import { MenuIcon } from '@assets/icons/sidebar';
import { EverysetLogoLink } from '@components/EverysetLogoLink';
import ProjectsDropdown from '@layouts/components/Header/ProjectsDropdown';
import { HEADER_HEIGHT } from '@lib/constants/layoutConstants';
import theme from '@styles/theme';

const { Header: HeaderLayout } = Layout;

const Header = ({
  setCollapsed,
}: {
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  return (
    <StyledHeader>
      <HeaderSection>
        <MenuIcon
          onClick={() => setCollapsed((state) => !state)}
          style={{ marginRight: 32, cursor: 'pointer' }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EverysetLogoLink width={104} />
          <Divider
            type='vertical'
            style={{
              height: 32,
              margin: '0 0 0 20px',
              borderInlineStart: `1px solid ${theme.palette.greyscale[8]}`,
            }}
          />
        </div>
        <div style={{ marginLeft: 20 }}>
          <ProjectsDropdown />
        </div>
      </HeaderSection>
    </StyledHeader>
  );
};

const StyledHeader = styled(HeaderLayout)`
  &.ant-layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1050;
    width: 100%;
    height: ${HEADER_HEIGHT}px;
    padding: 0 20px;
    background: ${(props) => props.theme.palette.greyscale[1]};
    box-shadow: 1px 1px 7px 1px rgba(64, 64, 64, 0.25);
  }
`;

const HeaderSection = styled.section`
  display: flex;
  align-items: center;
  padding-left: 6px;
`;

export default memo(Header);
