import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { useGetNextBookingDateQuery } from '@lib/redux.lib/slices/bookings/bookingsApiSlice';
import { useGetProjectUnitsQuery } from '@lib/redux.lib/slices/projects/projectsApiSlice';
import { RootState } from '@lib/redux.lib/store';

export const useGetSelectedBookingsDate = (): string => {
  const { projectId, selectedBookingDate } = useSelector(
    (state: RootState) => state.projects
  );

  const { data: units = [] } = useGetProjectUnitsQuery(projectId, {
    skip: !projectId,
  });
  const mainUnitId = units.find((unit) => unit.name === 'Main')?.id;
  const { data: nextBookingDate } = useGetNextBookingDateQuery(mainUnitId, {
    skip: !mainUnitId,
  });
  const selectedDate = selectedBookingDate
    ? selectedBookingDate
    : nextBookingDate
    ? dayjs(nextBookingDate).format('YYYY-MM-DD')
    : dayjs().format('YYYY-MM-DD');

  return selectedDate;
};
