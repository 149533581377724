import { ReactElement } from 'react';

const Lists = ({
  stroke = 'currentColor',
}: {
  stroke?: string;
}): ReactElement => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 12H20'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 19H20'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 5H20'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.8 4.8L6.00107 6L8 4'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.5 12.0006C6.49965 12.2766 6.27577 12.5001 5.99979 12.5C5.72381 12.4999 5.50012 12.2762 5.5 12.0002C5.49988 11.7242 5.72339 11.5003 5.99937 11.5C6.1322 11.4998 6.25963 11.5525 6.35355 11.6464C6.44748 11.7404 6.50017 11.8678 6.5 12.0006'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.5 19.0006C6.49965 19.2766 6.27577 19.5001 5.99979 19.5C5.72381 19.4999 5.50012 19.2762 5.5 19.0002C5.49988 18.7242 5.72339 18.5003 5.99937 18.5C6.1322 18.4998 6.25963 18.5525 6.35355 18.6464C6.44748 18.7404 6.50017 18.8678 6.5 19.0006'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Lists;
