import { ReactElement } from 'react';

const Dashboard = ({
  stroke = 'currentColor',
}: {
  stroke?: string;
}): ReactElement => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.6665 19.7715V22.1667C4.6665 23.4558 5.71067 24.5 6.99984 24.5H20.9998C22.289 24.5 23.3332 23.4558 23.3332 22.1667V12.5347'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.6665 15.6882V5.83333C4.6665 4.54417 5.71067 3.5 6.99984 3.5H20.9998C22.289 3.5 23.3332 4.54417 23.3332 5.83333V8.45133'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.10326 16.2862C6.90058 17.0835 6.90058 18.3763 6.10326 19.1736C5.30594 19.9709 4.01322 19.9709 3.21591 19.1736C2.41859 18.3763 2.41859 17.0835 3.21591 16.2862C4.01323 15.4889 5.30595 15.4889 6.10326 16.2862'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.1072 17.4965C15.1072 16.3695 16.0207 15.4548 17.1488 15.4548C18.277 15.4548 19.1905 16.3683 19.1905 17.4965C19.1905 18.6247 18.277 19.5382 17.1488 19.5382C16.0253 19.5428 15.1118 18.6363 15.1072 17.5128C15.1072 17.507 15.1072 17.5023 15.1072 17.4965Z'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3962 9.04941C13.1935 9.84673 13.1935 11.1394 12.3962 11.9368C11.5989 12.7341 10.3062 12.7341 9.50888 11.9368C8.71156 11.1394 8.71156 9.84672 9.50888 9.04941C10.3062 8.25209 11.5989 8.25209 12.3962 9.04941'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24.7839 9.04941C25.5812 9.84673 25.5812 11.1394 24.7839 11.9368C23.9866 12.7341 22.6939 12.7341 21.8966 11.9368C21.0993 11.1394 21.0993 9.84672 21.8966 9.04941C22.6939 8.25209 23.9866 8.25209 24.7839 9.04941'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.9217 11.9583L18.5383 16.0066'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.04321 16.2284L9.56655 11.9817'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.7616 16.0067L12.3433 11.9817'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Dashboard;
