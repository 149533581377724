import { ReactNode } from 'react';
import type { DropdownProps } from 'antd';
import { Dropdown as AntDropdown } from 'antd';
import styled from 'styled-components';

import { Button } from '@components/Button';

interface Props extends DropdownProps {
  title: JSX.Element | string | ReactNode;
  buttonType?: 'link' | 'text' | 'primary' | 'default' | 'ghost' | 'dashed';
  onClick?: (...args) => void;
  buttonStyle?: React.CSSProperties;
  buttonPlaceholder?: string;
}

const StylishDropdown = styled(AntDropdown)`
  &.ant-btn {
    box-shadow: none;
  }
`;

const Dropdown = ({
  title,
  buttonType = 'default',
  onClick,
  buttonStyle,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <StylishDropdown {...otherProps}>
      <Button type={buttonType} onClick={onClick} style={buttonStyle}>
        {title}
      </Button>
    </StylishDropdown>
  );
};

export { Dropdown };
