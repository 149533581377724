import { ReactElement } from 'react';

const Explore = ({
  stroke = 'currentColor',
}: {
  stroke?: string;
}): ReactElement => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='11.0586'
      cy='11.059'
      r='7.06194'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.229 8.46601C8.97946 7.71555 9.9973 7.29395 11.0586 7.29395C12.1199 7.29395 13.1378 7.71555 13.8882 8.46601'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.0033 20.0034L16.0517 16.0518'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Explore;
