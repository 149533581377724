import React, { ReactElement } from 'react';
import { Tooltip as AntTooltip, TooltipProps } from 'antd';

const Tooltip = ({ children, ...props }: TooltipProps): ReactElement => {
  return (
    <AntTooltip
      {...props}
      overlayInnerStyle={{ fontSize: 12, ...props.overlayInnerStyle }}
    >
      {children}
    </AntTooltip>
  );
};
export { Tooltip };
