import { memo, useState } from 'react';
import Link from 'next/link';
import router from 'next/router';
import type { MenuProps } from 'antd';
import { Space } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { CaretDownOutlined } from '@ant-design/icons';
import { EllipseCamera } from '@assets/icons';
import { Dropdown } from '@components/Dropdown';
import { SearchBar } from '@components/SearchBar';
import {
  useGetFilteredProjects,
  useGetProjects,
  useGetSortedProjects,
} from '@hooks/projects';
import { useGetCurrentProjectQuery } from '@lib/redux.lib/slices/projects/projectsApiSlice';
import type { RootState } from '@lib/redux.lib/store';

const ProjectsDropdown = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const projectId = useSelector((state: RootState) => state.projects.projectId);
  const { data: currentProject } = useGetCurrentProjectQuery(projectId, {
    skip: !projectId,
  });
  const { projects, isLoading, isFetching } = useGetProjects();
  const sortedProjects = useGetSortedProjects(projects);
  const filteredProjects = useGetFilteredProjects(sortedProjects, searchTerm);
  const items = getProjectsList(filteredProjects, setSearchTerm);
  const projectSelected = currentProject
    ? currentProject?.name
    : isLoading || isFetching
    ? 'Loading'
    : 'No Project Selected';

  return (
    <>
      <Dropdown
        title={
          <Space>
            <ProjectTitle>{projectSelected}</ProjectTitle>
            <CaretDownOutlined />
          </Space>
        }
        buttonType='text'
        menu={{ items }}
      />
    </>
  );
};

const getProjectsList = (projects, setSearchTerm) => {
  const searchItem = {
    key: 0,
    label: (
      <SearchBar
        placeholder='Search'
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    ),
    disabled: true,
  };
  const projectItems: MenuProps['items'] = projects
    .slice(0, 5)
    .map((project, i) => {
      const thumbnail: string =
        project?.image_header || project?.image_thumbnail;

      return {
        key: i + 1,
        label: (
          <div
            style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}
            onClick={() => router.push(`/${project?.id}/dashboard`)}
          >
            {thumbnail ? (
              <ImageThumbnail thumbnail={thumbnail} />
            ) : (
              <EllipseCamera />
            )}{' '}
            {project.name}
          </div>
        ),
      };
    });
  const viewAllProjectsLink = {
    key: 6,
    label: (
      <Link href='/' passHref legacyBehavior>
        <StyledLink>View All Projects</StyledLink>
      </Link>
    ),
  };
  const items = [searchItem, ...projectItems, viewAllProjectsLink];

  return items;
};

const ImageThumbnail = styled.div<{ thumbnail?: string }>`
  width: 35px;
  height: 35px;
  background-image: ${(props) => `url(${props?.thumbnail})`};
  background-size: cover;
  background-position: center;
  border-radius: 50%;
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.palette.primary[0]} !important;
`;

const ProjectTitle = styled.div`
  font-size: 17px;
`;

export default memo(ProjectsDropdown);
