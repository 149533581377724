import { ReactElement } from 'react';

const Bookings = ({
  stroke = 'currentColor',
}: {
  stroke?: string;
}): ReactElement => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='16.75'
      cy='17.75'
      r='4.25'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.1791 16.9085L16.3941 18.6924L15.3209 17.6225'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 15H7C4.79086 15 3 16.7909 3 19V20'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle
      cx='11'
      cy='7'
      r='4'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Bookings;
