import { useCallback } from 'react';
import type { InputProps } from 'antd';
import { Input } from 'antd';
import styled from 'styled-components';

import { SearchOutlined } from '@ant-design/icons';

interface IProps {
  debounceTimeMs?: number;
  disableDebounce?: boolean;
}

const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

const request = debounce((onChange, e) => {
  onChange(e);
}, 500);

const SearchBar = ({
  placeholder,
  onChange,
  debounceTimeMs = 100, // set to 5 so it's not noticeable
  disableDebounce,
  ...otherProps
}: InputProps & IProps): JSX.Element => {
  // START DEBOUNCE
  // other configurations of debounce dont work for some reason lol
  const debounceChange = useCallback(
    (onChange, value) => request(onChange, value),
    [onChange]
  );
  const _onChange = (ele) => {
    if (onChange) debounceChange(onChange, ele);
  };
  // END DEBOUNCE
  return (
    <StyledInput
      placeholder={placeholder || 'Start typing...'}
      prefix={<SearchOutlined />}
      onChange={disableDebounce ? onChange : _onChange}
      {...otherProps}
    />
  );
};

const StyledInput = styled(Input)`
  &.ant-input-affix-wrapper {
    padding: 8px 10px;
    font-size: ${(props) => props.theme.palette.textFontSize[7]};
    border: ${(props) => `1px solid ${props.theme.palette.border[1]}`};
    border-radius: 4px;
    height: 35px;

    &:hover {
      border-color: ${(props) => props.theme.palette.primary[0]};
    }

    &:active,
    &.ant-input-affix-wrapper-focused {
      border-color: ${(props) => props.theme.palette.primary[0]};
      box-shadow: ${(props) => props.theme.palette.shadow[1]};
    }

    input::placeholder {
      color: ${(props) => props.theme.palette.greyscale[5]};
    }

    svg {
      color: ${(props) => props.theme.palette.greyscale[5]};
      width: 1.3em;
      height: 1.3em;
    }
  }
`;

export default SearchBar;
