import { useSelector } from 'react-redux';

import { useGetProjectsQuery } from '@lib/redux.lib/slices/projects/projectsApiSlice';
import { RootState } from '@lib/redux.lib/store';

type UseGetProjects = {
  projects: ProjectWithAddress[];
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  refetch: () => void;
};

export const useGetProjects = (): UseGetProjects => {
  const userId = useSelector(
    (state: RootState) => state?.app?.user?.profile?.id
  );
  const impersonatedUserId = useSelector(
    (state: RootState) => state?.app?.user?.impersonatedUser
  );

  const currentUserId = impersonatedUserId || userId;
  const {
    data: projects = [],
    isLoading,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetProjectsQuery(currentUserId, { skip: !currentUserId });

  return { projects, isLoading, isFetching, isSuccess, isError, refetch };
};
