import { ReactElement } from 'react';

import theme from '@styles/theme';

const Companies = ({
  width = 30,
  stroke = theme.palette.greyscale[6],
  strokeWidth = '1.5',
}: {
  width?: number;
  stroke?: string;
  strokeWidth?: string;
}): ReactElement => (
  <svg
    width={width}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <ellipse
      cx='21.4193'
      cy='22.5865'
      rx='2.91788'
      ry='2.91788'
      transform='rotate(-180 21.4193 22.5865)'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle
      cx='8.58065'
      cy='22.5865'
      r='2.91788'
      transform='rotate(-180 8.58065 22.5865)'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <ellipse
      cx='15'
      cy='7.41354'
      rx='2.91788'
      ry='2.91788'
      transform='rotate(-180 15 7.41354)'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.4193 19.6686V18.5015C21.4193 16.89 20.113 15.5836 18.5015 15.5836H11.4985C10.7247 15.5836 9.9825 15.891 9.43529 16.4382C8.88808 16.9854 8.58066 17.7276 8.58066 18.5015V19.6686'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15 10.3314V15.5836'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Companies;
