const Menu = (props): JSX.Element => (
  <svg
    width='21'
    height='19'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M22.9999 3.38391H1.99993C1.88207 3.38391 1.78564 3.48034 1.78564 3.5982V5.31248C1.78564 5.43034 1.88207 5.52677 1.99993 5.52677H22.9999C23.1178 5.52677 23.2142 5.43034 23.2142 5.31248V3.5982C23.2142 3.48034 23.1178 3.38391 22.9999 3.38391ZM22.9999 20.0982H1.99993C1.88207 20.0982 1.78564 20.1946 1.78564 20.3125V22.0268C1.78564 22.1446 1.88207 22.2411 1.99993 22.2411H22.9999C23.1178 22.2411 23.2142 22.1446 23.2142 22.0268V20.3125C23.2142 20.1946 23.1178 20.0982 22.9999 20.0982ZM22.9999 11.7411H1.99993C1.88207 11.7411 1.78564 11.8375 1.78564 11.9553V13.6696C1.78564 13.7875 1.88207 13.8839 1.99993 13.8839H22.9999C23.1178 13.8839 23.2142 13.7875 23.2142 13.6696V11.9553C23.2142 11.8375 23.1178 11.7411 22.9999 11.7411Z'
      fill='#202020'
    />
  </svg>
);

export default Menu;
